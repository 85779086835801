import {Link} from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import {Waypoint} from 'react-waypoint'
import omakuva from '../assets/images/omakuva.jpg'
import jooga from '../assets/images/joogahetki.jpg'
import rento from '../assets/images/rentohetki.jpg'
import oma from '../assets/images/omahetki.jpg'
import treeni from '../assets/images/treenihetki.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import Ajankohtaista from "../components/Ajankohtaista";

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stickyNav: false,
        }
    }

    _handleWaypointEnter = () => {
        this.setState(() => ({stickyNav: false}))
    }

    _handleWaypointLeave = () => {
        this.setState(() => ({stickyNav: true}))
    }

    render() {
        return (
            <Layout>
                <Helmet title="Hyvinvointistudio Hetki"/>

                <Header/>

                <Waypoint
                    onEnter={this._handleWaypointEnter}
                    onLeave={this._handleWaypointLeave}
                ></Waypoint>
                <Nav sticky={this.state.stickyNav}/>

                <div id="main">

                    <section className="main">
                        <div className="spotlight">
                            <div className="content">
                                <p>
                                    Hyvinvointistudio Hetki tarjoaa kokonaisvaltaista hyvinvointia liikunnan ja
                                    rentoutuksen parissa - niitä omia, tärkeitä hetkiä, joita jokainen tarvitsee
                                    elämässään. Tule nauttimaan joogasta, hieronnasta, ohjatusta
                                    rentoutuksesta tai treenistä yksin, yhdessä ystävän kanssa tai
                                    pienryhmässä, silloin kuin sinulle parhaiten sopii.
                                </p>
                                <p>
                                    Olet lämpimästi tervetullut!
                                </p>
                            </div>
                        </div>
                    </section>

                    <section id="palvelut" className="main special">
                        <header className="major">
                            <h2>Palvelut</h2>
                        </header>
                        <ul className="features">
                            <li>

                                <span className="image">
                                    <img src={jooga} alt=""/>
                                </span>
                                <h3>Joogahetki</h3>
                                <p>
                                    Joogahetkessä harjoitukset tehdään rauhallisessa tahdissa, opetellen
                                    linjauksia ja asanoiden rakentamista. Tunti on lempeä ja jokainen tekee
                                    harjoituksen omaa kehoaan ja hengitystään kuunnellen. Käytämme
                                    erilaisia avustustekniikoita, jotka auttavat työskentelyäsi asanassa. Tunti
                                    sopii erityisesti sinulle, jolla ei ole aikaisempaa kokemusta joogasta, tai
                                    hiukan joogaa harjoittaneelle.
                                </p>
                                <p>
                                    Joogan tuntiaikataulut löytyvät ajankohtaista osiosta. Ilmoittauduthan
                                    tunnille etukäteen. Joogamatot ja -välineet ovat valmiina, mutta voit
                                    halutessasi tuoda myös oman maton.
                                </p>
                                <p>
                                    <Link to="/hinnasto#jooga">Hinnat alkaen 15€</Link>
                                </p>
                            </li>
                            <li>
                                <span className="image">
                                    <img src={oma} alt=""/>
                                </span>
                                <h3>Omahetki</h3>
                                <p>
                                    Tule viettämään oma hetki ja rentoutumaan koko kehon hierontaan tai
                                    kokeilemaan intialaista päähierontaa, jossa käydään läpi niska-
                                    hartiaseutu sekä tarvittaessa myös kädet. Tai valitse henkilökohtainen
                                    hyvinvointivalmennus, jossa voit yhdistää kehonhuoltoa, rentoutusta,
                                    hengitysharjoituksia, juuri sinun tarpeesi mukaan.
                                </p>
                                <p>
                                    <Link to="/hinnasto#oma">Hinnat alkaen 31€</Link>
                                </p>
                            </li>
                            <li>
                                <span className="image">
                                    <img src={rento} alt=""/>
                                </span>
                                <h3>Rentohetki</h3>
                                <p>
                                    Ohjattu rentoutus. Nämä hetket ovat niitä, jolloin päästät irti kaikesta
                                    muusta. Annat kehon rentoutua täysin, keskityt vain olemaan,
                                    kuuntelemaan ja nauttimaan hetkestä.
                                </p>
                                <p>
                                    <Link to="/hinnasto#rento">Hinnat alkaen 12€</Link>
                                </p>
                            </li>
                            <li>
                                <span className="image">
                                    <img src={treeni} alt=""/>
                                </span>
                                <h3>Treenihetki</h3>
                                <p>
                                    Treenihetkiä järjestetään toiveiden mukaan, esim. kehonpaino, HIIT,
                                    kahvakuula. Näissä hetkissä haastetaan kehoa, hengästytään ja
                                    kohotetaan kuntoa.
                                </p>
                                <p>
                                    Mahdollisuus myös henkilökohtaiseen ohjaukseen
                                    kunnonkohotusprojektissa tai ravinto- ja liikuntatottumusten
                                    päivittämisessä. Olen mielelläni mukana matkallasi tukemassa ja
                                    tsemppaamassa.
                                </p>
                                <p>
                                    <Link to="/hinnasto#treeni">Hinnat alkaen 40€</Link>
                                </p>
                            </li>
                        </ul>
                        <ul className="actions">
                            <li>
                                <Link to="/hinnasto" className="button special">
                                    Katso hinnasto
                                </Link>
                            </li>
                        </ul>

                    </section>


                    <Ajankohtaista/>

                    <section id="yritys" className="main">
                        <div className="spotlight">
                            <div className="content">
                                <header className="major">
                                    <h2>Tietoja yrityksestä</h2>
                                </header>
                                <p>
                                    Olen Hannele, joogaopettaja ja hyvinvointivalmentaja.
                                </p>
                                <p>
                                    Minulle ihmisen kokonaisvaltainen hyvinvointi, johon kuuluu liikunta,
                                    terveellinen ravinto, palautuminen ja lepo, ovat tärkeitä. Näiden asioiden
                                    sovittaminen, etenkin kiireiseen arkeen, voi olla joskus haastavaa ja
                                    tähän haluan tarjota apua ja vaihtoehtoja.
                                </p>
                                <p>
                                    Hyvinvointistudio Hetki tarjoaa liikunta- ja hyvinvointipalveluja sinulle,
                                    joka et pidä isoista harrasteryhmistä ja/tai haluat valita itsellesi sopivan
                                    ajankohdan. Tunteja on tarjolla yksityistunneista pienryhmätunteihin,
                                    max 6 henkilöä, ja joihin kuuluu henkilökohtaista ohjausta.
                                    Hyvinvointipalvelut sisältävät mm. hierontoja, ohjattuja rentoutuksia ja
                                    auttavat löytämään tarvittaessa juuri sinulle sopivia keinoja
                                    stressinhallintaan.
                                </p>
                                <p>
                                    Olen suorittanut Slow yoga opettajakoulutuksen 2018-2019 (200h),
                                    jonka opettajina toimivat Janne Kontala, Anneli Rautiainen ja Emma
                                    Paukku. Olen käynyt vuoden kestävän
                                    hyvinvointivalmentajakoulutuksen, suorittanut opinnot rentoutus- ja
                                    stressinhallintamenetelmistä, sekä lääketieteen perusteet (14 op).
                                </p>
                            </div>
                            <span className="image">
                                <img src={omakuva} alt=""/>
                            </span>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default Index
