import React from "react"
import {useStaticQuery, graphql} from "gatsby"

export default () => {
    const data = useStaticQuery(graphql`
        query AjankohtaistaQuery {
            allGoogleSheetAjankohtaistaRow(sort: {fields: pvm, order: DESC}) {
                edges {
                    node {
                        pvm(formatString: "DD.MM.YYYY")
                        title
                        content
                    }
                }
            }
        }
    `)
    const posts = data.allGoogleSheetAjankohtaistaRow.edges
    return (
        <section id="ajankohtaista" className="main">
            <header className="major">
                <h2>Ajankohtaista</h2>
            </header>
                {posts.map(({node}) => {
                    return (

                        <div key={node.title} className="post">

                            <h5 className="title">{node.title}</h5>
                            <div className="date">{node.pvm}</div>
                            <p>
                                {node.content.split('\n').map(function(item, key) {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br/>
                                        </span>
                                    )
                                })}
                            </p>
                        </div>
                    )
                })}
        </section>
    )
}
