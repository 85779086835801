import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['palvelut', 'ajankohtaista', 'yritys', 'yhteystiedot'] } currentClassName="is-active" offset={-300}>
            <li>
                <Scroll type="id" element="palvelut">
                    <a href="#">Palvelut</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="ajankohtaista">
                    <a href="#">Ajankohtaista</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="yritys">
                    <a href="#">Yritys</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="yhteystiedot">
                    <a href="#">Yhteystiedot</a>
                </Scroll>
            </li>
        </Scrollspy>
    </nav>
)

export default Nav
